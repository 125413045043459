/*import Split from 'split.js';*/
import Swiper, { Navigation, Pagination } from "swiper";

(function () {
  'use strict';

  const viewMoreLink = document.querySelector('.button[href="#begin"]');
  const formButtons = document.querySelectorAll('.request-proto');
  const formClose =  document.querySelector('.form-close');
  const formLayer = document.querySelector('.form-layer');
  const form = document.querySelector('.form');
  const formAction = document.querySelector('.form__actions');
  const formMessage = document.querySelector('.form-message');
  const cover = document.querySelector('.form-cover');

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  function handleDOMContentLoaded() {
    // Split
    /*Split(['.split--1', '.split--2'], {
      sizes: [50, 50],
      gutterSize: 0,
      minSize: 0
    });*/

    // Swiper config
    Swiper.use([Navigation, Pagination]);

    // Celebrities Swiper
    new Swiper('.celebrities', {
      loop: true,
      speed: 700,
      navigation: {
        prevEl: '.celebrities__navigation--prev',
        nextEl: '.celebrities__navigation--next'
      }
    });

    // Milestones Swiper
    new Swiper('.roadmap', {
      loop: false,
      initialSlide: 2,
      centeredSlides: true,
      pagination: {
        el: '.milestones__pagination',
        type: 'bullets',
        bulletClass: 'milestones__bullet',
        bulletActiveClass: 'milestones__bullet--active',
        clickable: true
      },
      breakpoints: {
        320: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 21
        },
        960: {
          slidesPerView: 3,
          spaceBetween: 42,
        }
      }
    });

    viewMoreLink.addEventListener('click', handleViewMore);

    formButtons.forEach((button) => button.addEventListener('click', handleShowForm));
    formClose.addEventListener('click', handleHideForm);
    form.addEventListener('submit', handleFormSubmit);
  }

  function handleViewMore(e) {
    e.preventDefault();
    const anchor = e.target.getAttribute('href');
    const element = document.querySelector(anchor);
    element.scrollIntoView({
      behavior: 'smooth'
    });
  }

  function handleShowForm(e) {
    e.preventDefault();

    formLayer.classList.add('form-layer--visible');
    cover.classList.add('form-cover--visible');
  }

  function handleHideForm(e) {
    e.preventDefault();

    formLayer.classList.remove('form-layer--visible');
    cover.classList.remove('form-cover--visible');
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    formAction.firstElementChild.setAttribute('disabled', 'disabled');
    const formData = new FormData(form);
    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(formData).toString()
    })
      .then(() => {
        formAction.classList.add('form__actions--hidden');
        formMessage.innerHTML = `<p>Ricevuto! La tua richiesta &egrave; stata presa in carico: ti invieremo alla tua email le indicazioni per poter accedere a Missivery.</p>`;
        formMessage.classList.add('form-message--ok');
      })
      .catch((error) => {
        formAction.firstElementChild.removeAttribute('disabled');
        formMessage.innerHTML = `<p>Oops! Qualcosa è andato storto. Ti consigliamo di riprovare più tardi oppure di contattaci direttamente all'indirizzo <a href="mailto:info@missivery.it">info@missivery.it</a>.</p>`;
        formMessage.classList.add('form-message--no');
      });
  }
})();
